<template>
    <el-button :size="size" :loading="loading" @click="exportChecked" type="primary">
        {{ buttonName }}
    </el-button>
</template>

<script>
    // import request from "@/api/request";
    // 需使用jquery ajax来检测下载进度
    export default {
        name: "exportModular",
        data() {
            return {
                loading: false
            };
        },
        props: {
            baseUrl: String,
            url: {
                type: String,
                required: true
            },
            params: {
                type: Object
            },
            fileName: {
                type: String,
                required: true
            },
            buttonName: {
                type: String,
                default: "导出"
            },
            fileType: {
                type: String,
                default: ".xls"
            },
            method: {
                type: String,
                default: "post"
            },
            isForm: {
                type: Boolean,
                default: true
            },
            // 处理startend
            handleStartEnd: {
                type: Boolean,
                default: false
            },
            /**
             * 转换时分秒
             * 例如：2020-09-14 00:00:00转为2020-09-14 23:59:59
             * 仅当handleStartEnd为true有效
             */
            fixTime: {
                type: Boolean,
                default: false
            },
            timeout: {
                type: Number,
                default: 60 * 1000 * 2
            },
            size: {
                type: String,
                default: "medium"
            }
        },
        methods: {
            // 导出
            exportChecked() {
                this.loading = true;
                setTimeout(() => {
                    if (this.loading) {
                        this.loading = false;
                    }
                }, 5 * 60 * 1000);
                let req_data;
                if (this.handleStartEnd && this.params && this.params.startEnd && this.params.startEnd.length == 2) {
                    let startTime, endTime;
                    startTime = this.params.startEnd[0];
                    if (this.fixTime) {
                        endTime = this.params.startEnd[1].$FixedZeroTime();
                    } else {
                        endTime = this.params.startEnd[1];
                    }
                    req_data = {
                        ...this.params,
                        startTime,
                        endTime
                    };
                } else {
                    req_data = this.params;
                }
                // this.$http({
                //     url: this.url,
                //     baseURL: this.baseUrl,
                //     blob: true,
                //     isForm: this.isForm,
                //     data: req_data,
                //     timeout: this.timeout,
                //     method: this.method,
                // })
                //     .then((res) => {
                //         this.loading = false;
                //         this.downloads(res);
                //     })
                //     .catch((res) => {
                //         this.loading = false;
                //         this.downloads(res);
                //     });

                this.ajaxExport(this.url, req_data)
                    .then(res => {
                        this.downloads(res);
                    })
                    .catch(err => {
                        this.downloads(err);
                    });
            },

            downloads(data) {
                if (!data) {
                    return false;
                }
                const url = window.URL.createObjectURL(data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.fileName + this.fileType);
                link.click();
            },

            ajaxExport(url, data) {
                const vm = this;
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        xhr: function () {
                            var xhr = new window.XMLHttpRequest();
                            //Download progress
                            xhr.addEventListener(
                                "progress",
                                function (evt) {
                                    if (evt.lengthComputable && evt.loaded / evt.total == 1) {
                                        vm.loading = false;
                                    }
                                },
                                false
                            );
                            return xhr;
                        },
                        type: "post",
                        url: window.config.baseUrl + url,
                        contentType: "application/json;charset=utf-8",
                        context: vm,
                        xhrFields: { responseType: "blob" },
                        beforeSend: function (xhr) {
                            let token = this.$util.getStor("token");
                            if (token != "") {
                                xhr.setRequestHeader("token", token);
                            }
                        },
                        data: JSON.stringify(data),
                        success: function (res) {
                            resolve(res);
                        },
                        error: function (err) {
                            vm.loading = false;
                            reject(err);
                        }
                    });
                });
            }
        }
    };
</script>

<style scoped></style>
