<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="处置单位名称" prop="cName">
                <el-input v-model="form.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa" :disabled="isDisabled"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="处置方式" prop="handleT">
                <el-select v-model="form.handleT">
                    <el-option v-for="t in handleTypes" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesBasicInfoHandleUnitList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesBasicInfoHandleUnitReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesBasicInfoHandleUnitInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesBasicInfoHandleUnitExport') && false"
                        url="/unitInfo/export"
                        fileName="处置单位管理"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                    <el-button v-if="btnexist('wastesBasicInfoHandleUnitImport') && false" size="small" type="primary">导入</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="处置单位名称" prop="cName" min-width="120"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa" min-width="180"></jy-table-column>
                <jy-table-column label="单位地址" prop="addr"  min-width="150"></jy-table-column>
                <jy-table-column label="处置方式" prop="handleT" show-overflow-tooltip  min-width="150"></jy-table-column>
                <jy-table-column label="联系人" prop="contact" min-width="120"></jy-table-column>
                <jy-table-column label="联系方式" prop="conPhone" min-width="140"></jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa" min-width="120"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT" min-width="160"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <add-update ref="addUpdate" @update="getList"></add-update>
        <institutions-tree
            :showType="[1]"
            ref="institutions"
            @addInstitutions="addInstitutions"
            title="选择所属机构"
            :onClose="closeInstitutions"
        ></institutions-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import AddUpdate from "@/components/pages/admin/basic/handleUnit/AddUpdate.vue";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        created() {
            this.getList();
            this.setOperateList();
            this.initDicts();
        },
        mixins: [btnMixins],
        methods: {
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictdelway").then(res => {
                    this.handleTypes = res.detail;
                });
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                let url = "/unitInfo/pageQuery";
                this.$http
                    .post(
                        url,
                        {
                            unitInfo: {
                                ...this.form,
                                unitType: "2"
                            },
                            orgIds: [],
                            mOrgIds: [],
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.unitInfo,
                                orgId: v.sysOrg && v.sysOrg.orgId,
                                orgNa: v.sysOrg && v.sysOrg.orgNa
                            };
                        });
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            add() {
                this.handleAddUpdate();
            },
            handleAddUpdate(options) {
                this.$refs.addUpdate.init(options);
            },
            handleDelete(row) {
                this.$confirm("是否删除该处置单位?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/unitInfo/delete";
                        this.$http.post(url, row.unitId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            if (this.dataList.length === 1 && this.pageIndex > 1) {
                                this.pageIndex--;
                            }
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
                this.isDisabled = false;
            },
            closeInstitutions() {
                this.isDisabled = false;
            },
            // 选择机构树
            selectInstitutions() {
                this.isDisabled = true;
                this.$refs.institutions.init();
            },
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleAddUpdate,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoHandleUnitUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoHandleUnitDelete");
                        }
                    }
                ];
                this.operateList = l;
            }
        },
        data() {
            return {
                loading: false,
                isDisabled: false,
                dataList: [],
                total: 0,

                form: {
                    cName: "",
                    handleT: "",
                    orgId: "",
                    orgNa: ""
                },

                pageIndex: 1,
                pageSize: 10,
                operateList: [],
                handleTypes: [],

                btnMenuId: "wastesBasicInfoHandleUnit"
            };
        },
        components: {
            ExportExcel,
            AddUpdate,
            institutionsTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
